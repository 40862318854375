export const NUMBER = 'NUMBER'
export const FRACTION = 'FRACTION'
export const COMPLEX = 'COMPLEX'
export const COMMAND = 'COMMAND'
export const STRING = 'STRING'
export const VAR = 'VAR'
export const VARCALL = 'VARCALL'
export const LIST = 'LIST'
export const KEYWORD = 'KEYWORD'
export const PROGRAM = 'PROGRAM'
export const IFTHENELSEEND = 'IFTHENELSEEND'
